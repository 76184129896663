import React from "react"
import { Link } from "gatsby"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import HeroHome from "../components/HeroHome"
import DayOfLearning from "../images/theme/img-home-about.png"
// import Subscribe from "../components/Subscribe";
// import SubFooterImage from "../images/theme/placeholder-image.png";
import FooterCallout from "../partials/footerCallout"
import Image from "../components/Image"
import amazingMeLogo from "../images/theme/logo-amazing-me.svg"
import ctaArrow from "../images/theme/img-cta-arrow.png"

const HomePage = () => (
  <Layout location={"home"}>
    <SEO title="Home" />
    {/***************  HERO ***************/}
    <Section className="hero__home">
      <HeroHome
        title=""
        // infographic="placeholder-image.png"
        subheading={
          <span>
            Elementary School Resources <br />
            for Body Confidence
          </span>
        }
        image="img-home-lead-kids.png"
      >
        <img
          className={"hero__amazing-me-logo"}
          src={amazingMeLogo}
          alt={"amazing me logo"}
        />
      </HeroHome>
    </Section>

    {/***************  CTA ***************/}
    <Section className="foster-healthy-bodies">
      <Container>
        <Link to={"/classroom-resources/"} className={"cta"}>
          Foster healthy bodies and minds with our NEW Classroom Resources!
          <img
            src={ctaArrow}
            alt={"Go to Family Resources"}
            className={"cta__arrow"}
          />
        </Link>
      </Container>
    </Section>

    {/***************  ABOUT THE PROGRAM && VIRTUAL FIELD TRIP ***************/}
    <Section className="about vft about-vft large-vertical-margin">
      <Container>
        <Row>
          <Column span={3}>
            <img
              src={DayOfLearning}
              className="subfooter-image"
              alt="Title: Facts, Info etc."
            />
          </Column>
          <Column span={8}>
            <h2>About the Program</h2>
            <p>
              The Dove Self-Esteem Project and Discovery Education have teamed
              up to deliver the best in social and emotional learning (SEL),
              health, and confidence-building resources for educators teaching
              grades 4-5. We believe a confident student makes a happy, healthy,
              and successful student, which is why we have created a suite of
              classroom and family resources focused on building self-esteem,
              promoting positive body image, and countering bullying.
            </p>
            <Link to={"/about"}>Learn More</Link>
          </Column>
          <Column span={6} className={"large-margin-top"}>
            <h2>Classroom Resources</h2>
            <p>
              Empower 4th and 5th grade students to be confident inside and out
              with evidence-based classroom resources focused on health,
              social-emotional learning (SEL), and body confidence.
            </p>
            <Link to={"/classroom-resources/"} className={"global__button"}>
              Learn More
            </Link>
          </Column>
          <Column span={6} className={"large-margin-top"}>
            <Image filename={"img-home-introduction.jpg"} />
          </Column>
          <Column span={6} className={"large-margin-top"}>
            <Image filename={"img-vft-live-thumbnail.png"} />
          </Column>
          <Column span={6} className={"large-margin-top"}>
            <h2>Body Positivity Virtual Field Trip</h2>
            {/*<h4 className={''}>TBD VFT Subheadline</h4>*/}
            <p>
              Each of us is unique, beautiful, and worth celebrating, but too
              often, things like appearance ideals, negative body image, and
              weight-based bullying get in the way of us appreciating our
              awesome selves. Students will hear from familiar characters from
              Cartoon Network's Steven Universe, as well as from their peers, as
              we explore how to be kinder to themselves and each other. They’ll
              learn to appreciate their bodies, get smarter about images they
              see online, and build strategies to fight weight-based bullying
              and teasing.
            </p>
            <Link to={"/virtual-field-trip/"} className={"global__button"}>
              Watch Now
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/*/!***************  VIRTUAL FIELD TRIP ***************!/*/}
    {/*<Section className={'virtual-field-trip'}>*/}
    {/*  <Container>*/}
    {/*    <Row>*/}
    {/*    */}
    {/*    </Row>*/}
    {/*  </Container>*/}
    {/*</Section>*/}

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section className={"squiggle"}>
      <Container>
        <h3>Download our Resources</h3>
        <Row>
          <Column span={5} className={"is-offset-1 resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-resources.png"}
            />
            <h4 className={"bold-text"}>Classroom Resources</h4>
            <p>
              Help your students realize their full potential with NEW classroom
              resources.
            </p>
            <Link to={"/classroom-resources"}>Learn More</Link>
          </Column>
          <Column span={5} className={"resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-family.png"}
            />
            <h4 className={"bold-text"}>Family Resources</h4>
            <p>
              Encourage body positivity and self-esteem at home with these
              important family resources.
            </p>
            <Link to={"/family-resources/"}>Learn More</Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  BODY CONFIDENCE FACT ***************/}
    <Section
      className={
        "midgray-background body-confidence-fact body-confidence-fact--home"
      }
    >
      <Container>
        <Row>
          <Column span={7}>
            <h2>Body Image Insights</h2>
            <p>
              Researchers have found that children begin to express concerns
              about their bodies as young as age 5.
            </p>
            <p>
              <em>- British Journal of Developmental Psychology</em>
            </p>
          </Column>
          <Column
            span={4}
            className={"is-offset-1 body-confidence-fact__container"}
          >
            <div className={"body-confidence-fact__container__image"}>
              <Image filename={"img-subfooter-1.png"} className={""} />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout />
  </Layout>
)
export default HomePage
