import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Column } from "../Grid"
import Image from "../Image"
// import Img from "gatsby-image"
import "./styles.scss"

const HeroHome = ({ title, subheading, text, image, infographic, children }) => {
  return (
    <Container>
      <Row>
        <Column span={6}>
          <div className="hero__left">
            {title && <h1 className="home__hero-title header-bar--yellow">{title}</h1>}
            <div className="hero__infographic">
              {children}
            </div>
            {subheading && <h2>{subheading}</h2>}
            {text && <p className='easy-reading-font home__hero-text'>{text}</p>}
          </div>
        </Column>
        <Column span={6}>
          <div className="hero__right">
            <Image filename={image} className="hero__image" />
          </div>
        </Column>
      </Row>
    </Container>
  )
};

HeroHome.propTypes = {
  title: PropTypes.string.isRequired,
  subheading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  text: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default HeroHome
